.margin-grid-wrapper {
	width: 100%;
	display: flex;
	flex-direction: column;
}

.margin-analysis-tabs {
	display: flex;
	flex: 1;
	flex-direction: column;
	overflow: auto;
	.tab-content {
		display: flex;
		flex: 1;
		overflow: hidden;
		.tab-pane {
			flex: 1;
			flex-direction: column;
			overflow: hidden;
			&.active {
				display: flex;
			}
		}
	}
}

// .all-trades-container {
// 	display: flex;
// 	flex-direction: column;
// 	justify-content: space-around;
// }
.margin-analysis-all-trades-grid {
	display: flex;
	flex-direction: column;
	justify-content: space-around;
}

.create-basket-btn-wrapper {
	display: flex;
	justify-content: right;
	padding-right: 8px;
	// margin-top: 6px;
	// margin-top: auto;
}

.margin-analysis-all-trades-grid,
.margin-analysis-all-baskets-grid,
.margin-analysis-comparison-results-grid,
.compactGridModal {
	::-webkit-scrollbar {
		width: 8px;
	}

	/* Track */
	::-webkit-scrollbar-track {
		-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
		box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
	}

	/* Handle */
	::-webkit-scrollbar-thumb {
		background: rgba(112, 112, 112, 0.8);
		-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
		box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
	}
	::-webkit-scrollbar-thumb:window-inactive {
		background: rgba(112, 112, 112, 0.8);
	}

	.ag-body-horizontal-scroll,
	.ag-body-horizontal-scroll-viewport,
	.ag-body-horizontal-scroll-container {
		max-height: 8px !important;
		min-height: 8px !important;
		height: 8px !important;
	}

	.ag-center-cols-viewport {
		overflow-x: hidden !important;
	}
	.ag-theme-alpine-dark .ag-cell {
		line-height: 24px !important;
	}
	.ag-theme-alpine-dark .ag-row {
		height: 28px !important;
	}
	.ag-theme-alpine-dark .ag-header-row {
		height: 28px !important;
		font-weight: normal !important;
	}
	.ag-theme-alpine-dark .ag-header {
		height: 33px !important;
		min-height: 33px !important;
		padding-left: 0px !important;
		padding-right: 0px !important;
	}
}

/**********Scroll Bar accross MA**************/
::-webkit-scrollbar {
	width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
	-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
	box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: rgba(112, 112, 112, 0.8);
	-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
	box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}
::-webkit-scrollbar-thumb:window-inactive {
	background: rgba(112, 112, 112, 0.8);
}

/************************/

.margin-trade-box {
	.tab-content {
		overflow: hidden;
	}
	.form-control {
		font-size: 16px;
		height: 36px;
		&::placeholder {
			/* Chrome, Firefox, Opera, Safari 10.1+ */
			color: var(--grey-4);
			opacity: 1; /* Firefox */
		}

		&:-ms-input-placeholder {
			/* Internet Explorer 10-11 */
			color: var(--grey-4);
		}

		&::-ms-input-placeholder {
			/* Microsoft Edge */
			color: var(--grey-4);
		}
	}
}

.old-summary-trade {
	max-height: 135px;
	overflow-y: auto;
}

.pea-tabs {
	.tab-pane {
		height: 100%;
	}
}

.green-Arrow {
	color: var(--green-3);
	transform: rotate(180deg);
}

.red-Arrow {
	color: var(--rc-slider-red);
}

.row-margin-detail {
	label {
		// overflow: hidden;
		display: block;
		text-overflow: ellipsis;
		white-space: nowrap;
	}
}

.color-snow {
	color: snow;
}
.color-snow:hover {
	color: snow;
}

.totalNetImValDelta {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	text-align: right;
	flex: 1;
}

