.asset-type-heading {
	position: relative;
	top: 10px;
	left: 33%;
}

.icon-info-position {
	margin-top: -3px;
}

.popover-info-icon {
	font-size: 10px;
	position: relative;
}

.react-numeric-input b {
	background: white !important;
}
