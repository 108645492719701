html,
body {
	height: 100%;
	width: 100%;
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
}

a {
	color: inherit;
	&:hover {
		text-decoration: none;
	}
}

input {
	outline: 0;
}

#root {
	display: flex;
	height: 100%;
	.container-fluid {
		padding-left: 1.25rem;
		display: flex;
		flex-direction: column;
		padding-bottom: 1.25rem;
		// padding-right: 4.25rem;
		overflow: auto;
		position: relative;
	}
}

.page-header {
	padding-right: 10px;
	display: flex;
	justify-content: space-between;
	font-size: 1.375rem;
	font-weight: 600;
	color: var(--grey-4);
	height: 74px;
	background-color: var(--common-header-bg);
	.header-sub-actions {
		display: flex;
		line-height: 1;
		padding: 20px;
	}
}

.sub-page-header {
	padding: 0.8125rem 0 0.8125rem 0;
	font-size: 1rem;
	font-weight: 600;
	color: var(--grey-5);
	border-bottom: 1px solid var(--grey-6);
	margin: 0 1.25rem;
}

.custom-control-label {
	line-height: 1.375rem;
	cursor: pointer;
	color: var(--grey-5);
}

.custom-control-input {
	&:checked {
		& ~ {
			.custom-control-label {
				color: var(--custom-control-checked);
			}
		}
	}
}

.info-container {
	background: var(--info-yellow);
	border-radius: 4px;
	border: 1px solid var(--grey-6);
}

.resizable {
	position: absolute;
	bottom: 0;
	right: 0;
	color: var(--grey-3);
	cursor: nwse-resize;
}

.footer-info {
	display: flex;
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background: var(--info-yellow);
	margin: 0 !important;
	align-items: center;
	justify-content: center;
}

.notification-wrapper {
	background: var(--primary);
	border-radius: 4px;
	padding: 0.8rem;
	max-width: 360px;
	position: relative;
	z-index: 10000;
	color: #fff;
	margin: 18px;
	.toast-header,
	.toast-body {
		background: transparent;
		color: #fff;
		padding: 0;
		display: block;
		.time {
			font-size: 0.75rem;
		}
		button {
			color: inherit;
		}
	}
	.toast-body {
		padding-top: 0.7rem;
	}
	.msg {
		color: var(--white);
		font-size: 0.875rem;
	}
	.close {
		position: absolute;
		right: 10px;
		top: 0px;
		color: var(--white);
		svg {
			transform: scale(0.7);
		}
	}
}

svg {
	vertical-align: sub;
}

.attachment-wrapper {
	border: 1px solid var(--grey-6);
	border-radius: 4px;
	background: var(--text-2);
	padding: 0.3rem 0.5rem;
	display: table;
	cursor: pointer;
	.file-name {
		color: var(--link-color);
		font-size: 0.875rem;
		font-weight: 600;
		padding-left: 0.2rem;
	}
	.size {
		font-size: 0.875rem;
		color: var(--grey-3);
		padding-left: 0.3rem;
	}
}

.auto-suggestion-dropdown {
	max-height: 150px;
	position: absolute;
	overflow: auto;
	background: var(--white);
	border: 1px solid var(--grey-3);
	border-radius: 4px;
	list-style: none;
	padding-left: 0;
	width: 143px;
	margin-left: 4px;
	margin-top: 2px;
	li {
		padding: 0.3rem 0.5rem;
		cursor: pointer;
		&:hover {
			background: $dropdown-link-hover-bg;
		}
	}
}

.auto-suggestion-dropdown-small {
	max-height: 100px;
	position: relative;
	top: 0;
	left: 0;
	overflow: auto;
	background: var(--white);
	border: 1px solid var(--grey-3);
	border-radius: 4px;
	list-style: none;
	padding-left: 0;
	li {
		padding: 0.3rem 0.5rem;
		cursor: pointer;
		&:hover {
			background: $dropdown-link-hover-bg;
		}
	}
}

.basket-popover-svg {
	pointer-events: none;
}

.activity-info,
.abcm-status {
	border-radius: 4px;
	height: 2.25rem;
	display: flex;
	font-size: 0.6875rem;
	.icon {
		display: flex;
		align-items: center;
		width: 35px;
		justify-content: center;
		flex: 0 0 auto;
	}
	.data {
		padding: 0.25rem 2rem 0.25rem 0.5rem;
		line-height: 13px;
		.status {
			font-weight: 700;
			text-transform: uppercase;
		}
	}
	&:after {
		content: '';
		width: 14px;
		height: 6px;
		display: block;
		position: absolute;
		right: 5px;
		top: 15px;
	}
	&.activity-success {
		border: 1px solid var(--activity-success-border);
		background: var(--activity-success-background);
		.icon {
			background: var(--activity-success-icon-background);
			color: var(--activity-success-icon-color);
		}
		.data {
			color: var(--activity-success-data-color);
		}
		&:after {
			background: url(../styles/svg/dropdown-arrow-success.svg) no-repeat;
		}
	}
	&.activity-warning {
		border: 1px solid var(--activity-warning-border);
		background: var(--activity-warning-background);
		.icon {
			background: var(--activity-warning-icon-background);
			color: var(--activity-warning-icon-color);
		}
		.data {
			color: var(--activity-warning-data-color);
		}
		&:after {
			background: url(../styles/svg/dropdown-arrow-warning.svg) no-repeat;
		}
	}
	&.activity-error {
		border: 1px solid var(--activity-error-border);
		background: var(--activity-error-background);
		.icon {
			background: var(--activity-error-icon-background);
			color: var(--activity-error-icon-color);
		}
		.data {
			color: var(--activity-error-data-color);
		}
		&:after {
			background: url(../styles/svg/dropdown-arrow-error.svg) no-repeat;
		}
	}
}

.activity-info {
	width: 230px;
}

.abcm-status {
	width: 110px;
	&.activity-success {
		&:after {
			background: none;
		}
	}
	&.activity-warning {
		&:after {
			background: none;
		}
	}
	&.activity-error {
		&:after {
			background: none;
		}
	}
}

.navbar-right {
	max-width: 40px;
	display: flex;
	flex-direction: column;
	transition: 0.5s all ease;
	overflow: hidden;
	.navbar-right-wrapper {
		min-width: 240px;
		overflow: hidden;
		display: flex;
		flex-direction: column;
		flex: 1;
		.nav-arrow {
			padding-right: 0.3rem;
			cursor: pointer;
			svg {
				transform: rotate(180deg);
			}
		}
	}
	&.active {
		max-width: 240px;
		overflow: auto;
		.navbar-right-wrapper {
			overflow: auto;
			.nav-arrow {
				svg {
					transform: rotate(0deg);
				}
			}
		}
	}
}

.no-basket-attached {
	width: 100%;
	background-color: var(--no-data-found);
	//min-height: 400px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	flex: 1;
	color: #666;
	border-radius: 4px;
}

.no-basket-attached-market-trade-data {
	width: 100%;
	background-color: var(--no-data-found);
	min-height: 100px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

table {
	thead {
		tr {
			th {
				font-weight: 600;
				color: var(--grey-10);
				border-bottom-width: 1px !important;
			}
		}
	}
	&.last-td-borderless {
		tbody {
			tr {
				&:last-child {
					td {
						border-bottom: none !important;
					}
				}
				td {
				}
			}
		}
	}
}

.limit-char-15 {
	max-width: 180px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.fixed-footer {
	position: absolute;
	bottom: 0;
	left: 6px;
	right: 6px;
	margin: auto;
	background: var(--white);
	box-shadow: 0 0 4px rgba(0, 0, 0, 0.18);
}

.header-container {
	border: 1px solid var(--grey-6);
	border-radius: 4px;
	.header {
		background: $table-header-bg;
		border-radius: 4px 4px 0 0;
		font-size: 0.8575rem;
		font-weight: 600;
		color: var(--grey-5);
		padding: 0.5rem 0 0.6537rem 1.25rem;
		border-bottom: 1px solid var(--grey-6);
	}
}

.security-initials {
	display: flex;
	align-items: center;
	font-size: 0.8125rem;
	font-weight: bold;
	color: var(--grey-5);
	padding: 8px 50px 9px 30px;
	background: var(--white);
	div {
		flex: 1;
		line-height: 25px;
		span {
			width: 25px;
			display: block;
			text-align: center;
			border-radius: 50%;
			height: 25px;
			cursor: pointer;
		}
		&.active {
			span {
				background: var(--security-initial-bg);
				color: var(--security-initial-active-color);
			}
		}
		&.disabled {
			span {
				color: #ccc;
				pointer-events: none;
			}
		}
	}
}

.pnl-analysis {
	border-radius: 4px;
	label {
		font-size: 0.75rem;
		font-weight: 600;
		padding: 10px 0 0 12px;
		text-transform: uppercase;
		line-height: normal;
	}
	span {
		font-size: 0.875rem;
		font-weight: 600;
		padding: 0 0 8px 12px;
		display: flex;
	}
	&.yellow {
		background: #ffe58d;
		label {
			color: #be710a;
		}
	}
	&.green {
		background: #c0f4a5;
		label {
			color: #138b37;
		}
	}
}

.empty-container {
	display: flex;
	flex-direction: column;
	flex: 1;
	align-items: center;
	justify-content: center;
	text-align: center;
	border: 2px dashed var(--grey-6);
	border-radius: 4px;
	margin: 0.8125rem 1.25rem;
}

.inefficiencies-config {
	width: 500px;
	height: 600px;
}

.settingsPopover-config {
	width: 460px;
	height: 380px;
}
.extraSettings-popover {
	width: 151px;
	height: 146px;
}
.security-list-types-content {
	width: 320px;
	height: 338px;
}
.security-list-auditDetailSection {
	margin-top: 8px;
}

.repo-datepicker .react-datepicker-popper {
	left: -118px !important;
	z-index: 11;
	.react-datepicker__triangle {
		left: auto;
		right: 50px;
	}
}

.popover {
	max-width: 100%;
	border: none;
	padding: 0;
	z-index: 1040;
	background: var(--dropdown-menu-bg);
	.arrow {
		top: calc((0.4rem + 1px) * -1);
		z-index: 1000;
		&:after {
			border-bottom-color: var(--dropdown-menu-bg);
		}
	}
	.popover-body {
		padding: 0;
	}
}

.label-new {
	background-color: $success;
	color: var(--white);
	max-width: 65px;
	text-align: center;
	font-size: 0.75rem;
	border-radius: 4px;
	position: relative;
	&:after {
		content: '';
		border-right: 10px solid var(--white);
		position: absolute;
		right: 0;
		top: 0;
		bottom: 0;
		border-top: 9px solid $success;
		border-bottom: 9px solid $success;
	}
}

.active-settlement-date {
	border-bottom: 3px solid var(--primary);
}

.override-number-input {
	-moz-appearance: textfield !important;
}

.override-number-input::-webkit-inner-spin-button,
.override-number-input::-webkit-outer-spin-button {
	-webkit-appearance: none !important;
}

input[type='number'] {
	padding: 0px 0px 0px 2px !important;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
	position: relative;
	top: 0px;
	left: 0px;
	height: 36px;
	cursor: pointer;
}

.sticky-bottom,
.sticky-top {
	position: sticky;
	bottom: 0;
	background: var(--white);
}

.sticky-top {
	top: 0;
	bottom: auto;
}

.manage-column-border:not(:last-child) {
	border-bottom: 1px solid var(--grey-6);
}

.ps__rail-y {
	z-index: 11;
}

.label-mb-0 {
	label {
		margin-bottom: 0;
	}
}

.bs-popover-bottom .arrow::before {
	border-bottom-color: rgba(0, 0, 0, 0.1);
}

.card {
	background: var(--card);
	box-shadow: var(--card-shadow-color);
}

.color-inherit {
	color: inherit;
}

.inventory-text {
	color: var(--inventory-text) !important;
}

.upload-btn {
	position: relative;
	cursor: pointer;
	input[type='file'] {
		position: absolute;
		width: 100%;
		height: 100%;
		opacity: 0;
		z-index: 1;
		&:before {
			content: '';
			cursor: pointer;
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
		}
	}
}

.table-open {
	.rt-td.rt-expandable {
		visibility: hidden !important;
	}
	.rt-tr {
		.rt-th:first-child {
			visibility: hidden !important;
		}
	}
}

.needsavail-table {
	.rt-thead,
	.rt-tbody {
		.rt-tr-group {
			padding: 0 !important;
		}
		.rt-tr {
			padding: 0 16px !important;
			.rt-td {
				overflow: visible !important;
			}
		}
	}
}

.table-content {
	.rt-thead,
	.rt-tbody {
		.rt-tr-group {
			padding: 0 !important;
		}
		.rt-tr {
			padding: 0 16px !important;
		}
	}
}

.send-basket-table,
.inv-optimization-table {
	.rt-tfoot {
		position: sticky;
		bottom: 0;
		background: var(--white);
	}
}

.no-pagination {
	.rt-tfoot {
		position: sticky;
		bottom: 0;
		background: var(--white);
		z-index: 2 !important;
	}
}

.sticky-foot {
	position: sticky;
	bottom: 0;
	background: var(--white);
	z-index: 2 !important;
}

.inv-optimization-table {
	.rt-th,
	.rt-td {
		padding-left: 1rem !important;
	}
	.ps__rail-x {
		z-index: 100000 !important;
	}
}

.decision-making-tabs {
	display: flex;
	flex: 1;
	flex-direction: column;
	overflow: auto;
	.tab-content {
		display: flex;
		flex: 1;
		overflow: hidden;
		.tab-pane {
			flex: 1;
			flex-direction: column;
			overflow: hidden;
			&.active {
				display: flex;
			}
		}
	}
}

.box.react-draggable,
.box.react-draggable.react-draggable-dragged {
	z-index: 10000;
	width: max-content;
	background-color: var(--card);
	border-radius: 10px;
	border: 1px solid var(--grey-9);
}

.frozen {
	order: -1;
	position: relative;
	left: 0;
	background: pink;
	transition: left 0.2s;
	margin-right: 6px;
}

.needs-availabilities-popover {
	.asset-type-heading {
		left: 37%;
	}
}

#loanDetailsDiv {
	.rt-tbody {
		.rt-td {
			overflow: visible !important;
		}
	}
	.rt-tfoot {
		z-index: 1;
		position: sticky;
		bottom: 0;
		background: var(--white);
	}
}

.ps__thumb-y {
	background-color: var(--scroll-color) !important;
}

.ps__rail-x {
	z-index: 100 !important; // scrollbar on Modal issue
}

.legend-color {
	height: 15px;
	width: 15px;
	border-radius: 50%;
}

.cash-out {
	background-color: #f48888;
}

.cash-in {
	background-color: #8bcc9e;
}

.sl-color-one {
	background-color: #dfc488;
}

.sl-color-two {
	background-color: #88dfd5;
}

.sl-color-three {
	background-color: #8896df;
}

.sl-color-four {
	background-color: #ce88df;
}

.sl-color-five {
	background-color: #0762ef;
}

.sl-color-six {
	background-color: #f7931e;
}

.simm-change-wrapper {
	width: 100%;
	display: flex;
	flex: 1;
	.no-result {
		flex: 1;
		background-color: var(--grey-15);
		border: 1px dashed var(--grey-3);
		margin: 18px 21px;
		border-radius: 6px;
		display: flex;
		align-items: center;
		justify-content: center;
		div {
			max-width: 200px;
			text-align: center;
			font-size: 14px;
			font-weight: 600;
			color: var(--grey-13);
		}
	}
}

.summary-table {
	.rt-tbody {
		.rt-td {
			border-right: 1px solid var(--border-color) !important;
		}
	}
}

.chart-component {
	background-color: #f6f6f6;
}

.loader-wrapper {
	position: relative;
	.comp-loader {
		display: none;
		position: absolute;
		z-index: 50;
		left: 0;
		top: 0;
		bottom: 0;
		right: 0;
		margin: auto;
		width: 30px;
		height: 30px;
		transform: scale(1.5);
	}
	&:after {
		content: '';
		position: absolute;
		width: 100%;
		height: 100%;
		background: var(--component-loader-overlay);
		z-index: 10;
		display: none;
	}
	&.-loading {
		.comp-loader {
			display: block;
		}
		&:after {
			display: block;
		}
	}
}

#computeOptimizationTable {
	.rt-tfoot {
		z-index: 1;
		position: sticky;
		bottom: 0;
		background: var(--white);
	}
}

div[id^='buyVerticalChart'],
.sellVerticalChart,
.maturingTradesChart {
	.amcharts-Container.amcharts-Label.amcharts-AxisLabel {
		fill: var(--black);
	}
	.amcharts-Sprite-group.amcharts-Grid-group {
		stroke: var(--black);
	}
	.amcharts-Container.amcharts-Label {
		fill: var(--white);
	}
}

#predictedcurvechart,
#ratechangecurvechart,
#tracking_basket,
#interesetRatesId,
div[id^='tracking_basket'] {
	.amcharts-Container.amcharts-Label.amcharts-AxisLabel {
		fill: var(--black);
	}
	.amcharts-Sprite-group.amcharts-Grid-group {
		stroke: var(--black);
	}
	.amcharts-Container.amcharts-Label {
		fill: var(--badge-green);
	}
}

.fm-ui-element.fm-ui {
	background-color: inherit;
}

.icon-negative-average {
	color: var(--negative-average);
}

.icon-negative-deficient {
	color: var(--negative-deficient);
}

.icon-negative-substandard {
	color: var(--negative-substandard);
}

.icon-positive-average {
	color: var(--positive-average);
}

.icon-positive-decent {
	color: var(--positive-decent);
}

.icon-positive-superior {
	color: var(--positive-superior);
}

#collateral-basket {
	.rt-tfoot {
		z-index: 1;
		position: sticky;
		bottom: 0;
		background: var(--white);
	}
}

.ag-theme-alpine {
	color: var(--black) !important;
	&.ag-theme-alpine-dark {
		color: var(--white) !important;
	}
}

.hide-show-col {
	max-height: 300px !important;
}

.custom-popover-basic {
	background: transparent;
	z-index: 11111 !important;
}

.custom-popover-content {
	background: var(--white);
	color: var(--black);
	position: relative;
}

.suggestions-btn {
	color: var(--nav-active);
	font-weight: bold;
	margin: auto;
	.custom-control-label {
		color: var(--nav-active) !important;
	}
}

.custom-popover-button {
	position: relative;
	bottom: 15px;
}

.ag-status-bar {
	min-height: 35px !important;
}

.text-underline {
	text-decoration: underline;
}

.create-basket-avn {
	padding: 0 2px 0 2px;
}

.greyed-out {
	opacity: 0.4;
}

.non-cash-menu-btn {
	background: $blue;
	padding: 5px 10px 5px 10px;
	color: #fff;
	border-radius: 4px;
}

.fm-charts-color-1 {
	fill: #00a45a !important;
}

.fm-charts-color-2 {
	fill: #df3800 !important;
}

.fm-charts-color-3 {
	fill: #ffb800 !important;
}

.fm-charts-color-4 {
	fill: #6d3bd8 !important;
}

.fm-charts-color-5 {
	fill: #0075ff !important;
}

#fm-yAxis-label,
#fm-xAxis > text,
#fm-yAxis > text {
	display: none;
}

#fm-pivot-view .fm-grid-layout .fm-cell {
	font-size: 15px;
}

#fm-grid-view span,
#fm-grid-view div,
#fm-grid-view td {
	font-family: Verdana !important;
}

.bg-lightcoral {
	background-color: rgb(241, 155, 155) !important;
}

.ag-default-panel {
	z-index: 11 !important;
}

.execution-count {
	background: var(--count-bg);
	min-width: 1.4rem;
	min-height: 1rem;
	border-radius: 4px;
	position: absolute;
	color: var(--count-color);
	font-weight: 600;
	right: 20px;
	text-align: center;
	&.label-notification {
		right: 50px;
	}
}

.fm-toolbar-button {
	position: absolute;
	right: 50px;
	top: 10px;
	z-index: 1;
	border: 1px solid #df3800;
	background: #fff;
	color: #df3800;
	text-transform: uppercase;
	cursor: pointer;
	margin: 3px 0;
	&.widget-toolbar {
		right: 35px;
		top: 1px;
		margin: 0px;
		padding: 0px 5px;
		border: none;
		font-size: 28px;
		background: transparent;
	}
	&.gridlayout-toolbar {
		right: 40px;
		top: 7px;
		margin: 0px;
		padding: 0px 5px;
		border: none;
		font-size: 28px;
		background: transparent;
	}
}

button.rec-dot {
	background-color: var(--primary);
}

button.rec-arrow {
	background-color: var(--primary);
}

button.rec-dot:hover,
button.rec-dot:active,
button.rec-dot:focus {
	box-shadow: 0 0 1px 3px rgba(43, 40, 40, 0.5);
}

.flex-one {
	flex: 1;
}

.flex-two {
	flex: 2;
}

.abc-portfolio-popup-dropdown {
	max-height: 500px !important;
	.status-wrapper {
		display: flex;
		flex-direction: column;
		overflow: hidden;
		max-height: 400px;
	}
}

.abc-portfolio-popup-save-btn {
	float: right;
	position: sticky;
	top: 0px;
}

.new-request-dropdown {
	.dropdown-item {
		line-height: 1;
	}
}

.p2p-dropdown-item {
	.dropdown-item {
		@extend.text-truncate;
	}
}
.cb-schedule-dropdown-menu {
	.dropdown-item {
		font-size: 12px !important;
	}
}

.p2p-dropdown-label {
	label {
		display: flex;
	}
}

.table-container {
	height: calc(100% - 56px);
	overflow: auto;
}
.header-logo {
	padding: 20px 20px;
	background-color: var(--header-logo-bg);
	// line-height: 1;
	height: 74px;
	max-height: 74px;
	width: 225px;
	min-width: 225px;
	box-shadow: 1px 0px 4px rgba(0, 0, 0, 0.16);
}
.header-logo-color {
	color: var(--header-logo-color);
}
#main-content {
	padding: 4px 4px 4px 51px;
	overflow-y: auto !important;
	overflow-x: hidden !important;
}

.maturing-trades {
	.tab-content {
		overflow: auto;
		padding-right: 10px;
	}
}
.header-title {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	min-width: 133px;
	// max-width: 300px;
	margin-right: 20px;
	display: block !important;
}
.search-header-component {
	position: absolute;
	right: 30px;
}
.widget-icon {
	@extend .text-light-grey;
}
.nav-profile-color {
	color: var(--profile-text-color);
}
.error-page-text {
	color: #666666;
}
.search-security-modal {
	.modal-content {
		height: 600px;
	}
}
.security-search-header {
	color: var(--link-color);
}
.select-basket-label {
	position: absolute;
	top: 0;
	right: 0;
	justify-content: space-between;
	margin-right: 10px;
}
.filter-icon-new {
	background-image: url(./svg/filter.svg);
	background-repeat: no-repeat;
	background-size: contain;
	background-position: center;
}
.save-icon {
	background-image: url(./svg/save.svg);
	background-repeat: no-repeat;
	background-size: contain;
	background-position: center;
}
.tag-order-indicator {
	font-size: 10px;
	background-color: #a5b2d5;
	padding: 0 3px;
	border-radius: 2px;
	width: 14px;
	max-height: 35px;
	margin-left: 4px;
	justify-content: center;
	span {
		transform: rotate(90deg);
		position: relative;
		// left: -10px;
		left: 7px;
		font-size: 8px;
	}
}

.list-style-none {
	list-style: none;
}

.display-flex {
	display: flex;
}

.disabled-DatePicker {
	background-color: rgb(41, 41, 41) !important;
}
.disabled-field {
	background-color: rgb(41, 41, 41) !important;
}
.multiline {
	white-space: pre-wrap;
}

.feature-disabled {
	background: rgba(0, 0, 0, 0.3);
	height: 100%;
	width: 100%;
	cursor: not-allowed;
}
